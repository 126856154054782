import './App.css';

import React from 'react';

import useToken from './common/useToken';

import Login from './modules/login/Login';
import Main from './modules/main/Main';
import useData from './common/useData';

function App() {
    const { token, setToken } = useToken();
    const { setData } = useData();

    React.useEffect(async () => {
        const loggedUserJSON = window.localStorage.getItem('loggedUserToken');
        const loggedUserData = window.localStorage.getItem('loggedUserData');
        
        if(loggedUserJSON && loggedUserData) {
            const Token = JSON.parse(loggedUserJSON);
            const user = JSON.parse(loggedUserData);

            const response = await fetch('https://back.ugt-sp.es/authToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({token: Token})
            })
            const data = await response.json()

            if(data.verify) {
                setToken(Token);
                setData(user);
            }
        }
    }, []);

    if(!token) {
        return <Login />
    }
    
    return (
        <>
            {token ? 
                <Main/>
            : 
                <Login />
            }
        </>
    );
}

export default App;
