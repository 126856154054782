import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useEffect } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  width: '35vw'
};

export const ModalForgot = ({open, setOpen}) => {
    const [dni, setDni] = useState('')

    useEffect(() => {
        setDni('')
    }, [open])

    return (
        <Modal
            open={open}
        >
            <Box sx={style}>
                <div className="modal-header">
                    <h2 id="parent-modal-title">Recuperar Contraseña</h2>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setOpen(false)}></button>
                </div>

                <p id="parent-modal-description">
                    Introduce tu DNI para recuperar tu contraseña
                </p>

                <div className="form-group-login mt-3">
                    <label htmlFor="username">DNI</label>
                    <input type="text" className="form-control" id="username" placeholder="Introduce el DNI" value={dni} onChange={e => setDni(e.target.value)} />
                </div>

                <button type="button" className="btn btn-primary mt-3 w-100" onClick={() => {
                    fetch('https://back.ugt-sp.es/resetEmployeePassword', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({dni: dni})
                    }).then(res => res.json()).then(response => {
                        if(response.updated) {
                            NotificationManager.success("Se te ha enviado un email con los pasos para cambiar la contraseña.", 'Contraseña cambiada', 3000);
                            setOpen(false);
                        } else {
                            NotificationManager.error("Error al restablecer la contraseña, ponte en contacto con algún responsable.", 'Error', 3000);
                        }
                    })
                }}>Enviar</button>
            </Box>
        </Modal>
    );
}